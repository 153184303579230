.img-sec {
  display: none !important;
  height: 7rem;
  display: flex;
  align-items: center;
  .slick-list {
    padding: 70px 50px !important;
  }
}
.img-sec .slick-active {
  transition: all 0.3s ease;
  position: relative;
  &.slick-center.slick-current {
    & .MuiAvatar-root {
      transform: scale(1.5);
      transition: all 0.3s ease;
    }
    & .MuiTypography-root {
      display: block;
      transition: all 0.3s ease;
      transform: translateY(1.5rem) scale(1.5);
    }

    &::before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 4px;
      transform: scale(1.25);
      background: black;
      top: -50px;
      left: 0px;
    }
  }
}
